export default [
  {
    title: 'Experts',
    icon: 'UserIcon',
    route: 'experts-list',
    resource: 'auth',

  },
  {
    title: 'Events ',
    icon: 'ServerIcon',

    children: [
      {
        title: 'Dynamic Events',
        route: 'events-list-dynamic',
        resource: 'auth',
      },
      {
        title: 'Fixed Events',
        route: 'events-list-fixed',
        resource: 'auth',
      },
    ]
  },
  {
    title: 'My Bookings',
    icon: 'ListIcon',
    children: [
      {
        title: 'Upcoming Bookings',
        route: 'bookings-list-upcoming',
        resource: 'auth',
      },
      {
        title: 'Past Bookings',
        route: 'bookings-list-past',
        resource: 'auth',
      }
    ]
  },
]
