<template>
  <b-nav-item-dropdown
      v-if="$can('manage', 'admin')"
      class="dropdown-cart mr-25"
      menu-class="dropdown-menu-media"
      right
      @show="GET_CART"
      ref="navdropdown"
  >
    <template #button-content>
      <feather-icon
          v-if="CART.items"
          :badge="Object.keys(CART.items).length"
          class="text-body"
          icon="ShoppingCartIcon"
          size="21"
      />

      <feather-icon
          v-else
          :badge="0"
          class="text-body"
          icon="ShoppingCartIcon"
          size="21"
      />


    </template>

    <!-- Header -->


    <li class="dropdown-menu-header" v-if="CART.total">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('My Cart') }}
        </h4>
        <b-badge
            pill
            variant="light-primary"

        >
          {{ CART.items.length }} {{ $t('Items') }}
        </b-badge>
      </div>


    </li>

    <!-- Cart Items -->
    <vue-perfect-scrollbar
        v-if="CART.items"
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
    >
      <b-media
          v-for="item in CART.items"
          :key="item.name"
      >
        <template #aside>
          <b-img
              :src="require('@/assets/images/illustration/personalization.svg')"
              :alt="item.name"
              rounded
              width="62px"
          />
        </template>
        <feather-icon
            icon="XIcon"
            class="cart-item-remove cursor-pointer"
            @click="deleteFromCart(item.id)"
        />

        <span class="text-muted">{{ item.qty }} Credits</span>

        <h5 class="cart-item-price mr-2">
          {{ item.qty }} €
        </h5>
      </b-media>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li
        v-if="CART.total"
        class="dropdown-menu-footer"
    >
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">
          {{ $t('Total') }}:
        </h6>
        <h6 class="text-primary font-weight-bolder mb-0 mr-2">
          {{ CART.total }} €
        </h6>
      </div>
      <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          class="mt-1"
          block
          @click="$refs.navdropdown.visible = false"
          :to="{ name: 'credits-details' }">
        {{ $t('Proceed to checkout') }}
      </b-button>
    </li>
    <p
        v-if="!CART.total"
        class="m-0 p-1 text-center">
      {{ $t('Your cart is empty') }}
    </p>


  </b-nav-item-dropdown>

</template>

<script>
import {BBadge, BButton, BFormSpinbutton, BImg, BLink, BMedia, BNavItemDropdown,} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

import {mapGetters, mapActions} from 'vuex';

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BImg,
    BFormSpinbutton,
    VuePerfectScrollbar,
    BButton,

  },
  directives: {
    Ripple,
  },
  data() {
    return {

      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
        loaded: false,

        currentItem: {
          id: '',
          productId: '',
          qty: '',
        },
      },
    }
  },

  methods: {
    ...mapActions(['GET_CART', 'DELETE_CART_ITEM']),


    deleteFromCart(id) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t('You won\'t be able to revert this!'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$i18n.t('Cancel'),
        confirmButtonText: this.$i18n.t('Yes, delete it!'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(() => {
        this.DELETE_CART_ITEM(id).then(() => {
          this.GET_CART();
        })
      })

    },

  },
  computed: {
    ...mapGetters(['CART']),
    totalAmount() {
      let total = 0
      this.CART.items.forEach(i => {
        total += i.price
      })
      return total
    },
  },

  mounted() {
    this.GET_CART();
  }
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>
