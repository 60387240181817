<template>
  <layout-event-room>
    <router-view />
  </layout-event-room>
</template>
<script>
import LayoutEventRoom from '@core/layouts/layout-event-room/LayoutEventRoom.vue'

export default {
  components: {
    LayoutEventRoom,
  },
}
</script>
