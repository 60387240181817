export default [
    {
        header: 'Help',
    },

    {
        title: 'FAQ',
        icon: 'CreditCardIcon',
        route: 'faq',
    },
]