<template>
  <div class="px-4">
    <b-navbar
        :toggleable="false"
        class="header-navbar navbar navbar-shadow align-items-center header-navbar-event-room">
      <slot name="navbar">
        <app-navbar-vertical-layout />
      </slot>
    </b-navbar>
    <router-view/>
  </div>
</template>
<script>
import AppNavbarVerticalLayout from '@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue'
import {BNavbar,BButton} from 'bootstrap-vue'
import VerticalNavMenu from '../layout-vertical/components/vertical-nav-menu/VerticalNavMenu.vue'
export default {
  components: {
    BButton,
    AppNavbarVerticalLayout,
    VerticalNavMenu,
    BNavbar,
  },
}
</script>
<style scoped>
.header-navbar-event-room {
  position: sticky;
  top: 1.3rem;
  margin: 1.3rem 0;
}
</style>



