export default [
  {
    title: 'Buy credits',
    route: 'buy-credits',
    icon: 'DollarSignIcon',
  },

  {
    title: 'Distribute credits',
    route: 'distribute-credits',
    icon: 'Share2Icon',
  },

  {
    title: 'Previous charges',
    route: 'buy-credits-list',
    icon: 'ListIcon',
  }
]
