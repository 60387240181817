<template>
  <ul :style="colors">
    <component :is="resolveNavItemComponent(item)" v-for="item in items" :key="item.header || item.title"
      :item="item"/>
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  data() {
    return {
      theme: {
        sidebar: {
          backgroundColor: ""
        }
      }
    }
  },
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  watch: {
    '$store.state.appConfig.theme': function() {
      if (this.$store.state.appConfig.layout.skin == 'dark') {
        this.theme.sidebar.backgroundColor = ""
      } else {
        this.theme.sidebar.backgroundColor = this.$store.state.appConfig.theme.sidebar.background
      }
    }
  },
  computed: {
    colors() {
      let colorsFromDB = JSON.parse(localStorage.getItem('userData'))?.client?.colors;
      if (colorsFromDB?.sidebar?.background?.length) {
        this.theme.sidebar.backgroundColor = colorsFromDB?.sidebar?.background
        return this.theme.sidebar
      } else {
        return this.theme.sidebar
      }
    }
  },

  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
}
</script>
