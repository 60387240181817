<template>
  <div class="main-menu menu-fixed menu-accordion menu-shadow" :class="[
    { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
    skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
  ]" :style="sidebarBackground" @mouseenter="updateMouseHovered(true)" @mouseleave="updateMouseHovered(false)">
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot name="header" :toggleVerticalMenuActive="toggleVerticalMenuActive" :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon">
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link class="navbar-brand" to="/">

              <span class="brand-logo">
                <b-img class="img-size" :src="$store.getters.COMPANY_LOGO
                && $store.getters.COMPANY_LOGO != 'undefined' ? $store.getters.COMPANY_LOGO:appLogoImage" alt="logo" />
              </span>
            </b-link>
          </li>

        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <!--    <div-->
    <!--        :class="{'d-block': shallShadowBottom}"-->
    <!--        class="shadow-bottom"-->
    <!--    />-->

    <!-- main menu content-->
    <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="main-menu-content scroll-area" tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }">
      <vertical-nav-menu-items :items="navMenuItems" class="navigation navigation-main pb-3 " />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import {mapActions, mapGetters} from "vuex";
import store from '@/store'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  computed: {

  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    let colorsFromDB = JSON.parse(localStorage.getItem('userData'))?.client?.colors;
    let sidebarBackground = {};
    if (colorsFromDB?.sidebar?.background?.length) {
      sidebarBackground = {
        backgroundColor: colorsFromDB?.sidebar?.background,
      }
    }

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,

      sidebarBackground,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
.brand-text {
  margin-top: 0.6rem !important;
  span {
    display: block;
    font-size: 30px;
  }
}
.img-size{
}
.brand-logo img {
  width: 100% !important;
  height: 50px !important;
  object-fit: contain;
  margin-left: 40px !important;
}
.brand-logo{
  width: 180px !important;
  height: 50px !important;
}
.main-menu .navbar-header .navbar-brand {
  width: 180px !important;
}
</style>
