/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import adminHeader from './admin/header'
import adminCompanyProfile from './admin/company-profile'

import dashboard from './dashboard'
import users from './admin/employees'
import appsAndPages from './apps-and-pages'
import creditsHeader from './credits/header'
import credits from '@/navigation/vertical/credits/credits'
import bookingHeader from './booking/header'
import experts from './booking/experts'
import help from "@/navigation/vertical/help";
import payment from "@/navigation/vertical/admin/payment"

// Array of sections
export default [
    ...dashboard,
    ...adminHeader,
    ...adminCompanyProfile,
    ...users,
    ...payment,
    ...creditsHeader,
    ...credits,
    ...bookingHeader,
    ...experts,
    ...help


]
