<template>
  <li v-if="canViewVerticalNavMenuLink(item)" class="nav-item" :class="{
      'active': isActive,
      'disabled': item.disabled
    }">

    <b-link :style="colors" v-bind="linkProps" class="d-flex align-items-center"
      v-if="(item.admin && userData.isAdmin) || !item.admin">
      <feather-icon :icon="item.icon || 'CircleIcon'" :style="colors.icon" />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge v-if="item.tag" pill :variant="item.tagVariant || 'primary'" class="mr-1 ml-auto">
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      theme: {
        navitem: {
          color: "",
          backgroundColor: "",
          active: {
            backgroundColor: "",
            color:""
          },
          hover: {
            backgroundColor:"",
            color: ""
          },
          icon:{
            color: ""
          }
        }
      },
      userData: JSON.parse(localStorage.getItem('userData')),
    };
  },
  mounted() {
    // console.debug(this.userData.isAdmin)
  },

  watch: {
    '$store.state.appConfig.theme': function() {
      if (this.$store.state.appConfig.layout.skin == 'dark') {
        this.theme.navitem.color = ""
        this.theme.navitem.active = []
        this.theme.navitem.hover = []

      } else {
        this.theme.navitem.color = this.$store.state.appConfig.theme.sidebar.navitem.textcolor
        // this.theme.navitem.backgroundColor = this.$store.state.appConfig.theme.sidebar.navitem.background
        this.theme.navitem.active = this.$store.state.appConfig.theme.sidebar.navitem.active
        this.theme.navitem.hover = this.$store.state.appConfig.theme.sidebar.navitem.hover
      }
    }
  },
  computed: {
    colors() {
      let colorsFromDB = JSON.parse(localStorage.getItem('userData'))?.client?.colors;
      if (colorsFromDB?.sidebar?.navitem?.background?.length) {
        this.theme.navitem.backgroundColor = colorsFromDB?.sidebar?.navitem?.background
        this.theme.navitem.color = colorsFromDB?.sidebar?.navitem?.textcolor + '!important'
        this.theme.navitem.icon.color = colorsFromDB?.sidebar?.navitem?.textcolor + '!important'
        this.theme.navitem.active.backgroundColor = colorsFromDB?.sidebar?.navitem?.active?.background + '!important'
        this.theme.navitem.active.color = colorsFromDB?.sidebar?.navitem?.active?.textcolor + '!important'
        this.theme.navitem.hover.color =  '#000 !important'
        this.theme.navitem.hover.backgroundColor = '#000 !important'

        return this.theme.navitem
      } else {
        return this.theme.navitem
      }
    }
  },

  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },

}
</script>
