<template>
  <b-badge pill :variant="badgeColor" class="mx-md-1" v-if="CREDITS !== null">
    {{ CREDITS }} {{ $t("Credits") }}
  </b-badge>
</template>

<script>
import {BBadge,} from 'bootstrap-vue'
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Credits",
  components: {
    BBadge
  },
  methods: {
    ...mapActions(['UPDATE_CREDITS'])
  },
  beforeMount() {
    this.UPDATE_CREDITS()
  },
  computed: {
    ...mapGetters(['CREDITS']),
    badgeColor() {
      if (this.CREDITS < 200) return 'danger'
      if (this.CREDITS >= 200 && this.CREDITS  < 500) return 'warning'
      if (this.CREDITS >= 500) return 'success'
      return 'primary'
    }
  }
}
</script>
<style>
@media screen and (max-width: 560px){
  .currency_symbol{
    display: none;
  }
}
</style>
