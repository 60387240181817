export default [
    {
        title: 'Payment',
        icon: 'CreditCardIcon',
        children: [
            {
                title: 'Invoices',
                icon: 'FileTextIcon',
                route: 'invoice-list',
            },
            {
                title: 'Invoice Adresses',
                icon: 'FilePlusIcon',
                route: 'invoice-adress-list',
            },
            {
                title: 'Payment Methods',
                icon: 'CreditCardIcon',
                route: 'payment-method-list',
            },
        ]
    },
]