<template>
  <b-nav-item-dropdown
      id="dropdown-grouped"
      variant="link"
      class="dropdown-language"
      right
  >
    <template #button-content>
      <b-img
          :src="currentLocale.img"
          height="14px"
          width="22px"
          :alt="currentLocale.locale"
      />
      <span class="ml-50 text-body lang-name"
            :style="theme.topbar"
      >
        {{ currentLocale.name }}
      </span>

    </template>

    <b-dropdown-item
        v-for="localeObj in locales"
        :key="localeObj.locale"
        @click="changeLang(localeObj)"
    >
      <b-img
          :src="localeObj.img"
          height="14px"
          width="22px"
          :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>

  </b-nav-item-dropdown>
</template>

<script>
import { BDropdownItem, BImg, BNavItemDropdown } from 'bootstrap-vue'
import { localize } from 'vee-validate'
import { EventBus } from '@/@core/mixins/eventBus'

export default {
  data() {
    return {
      theme: {
        topbar: {
          color: ''
        },
      }
    }
  },
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },

  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },

  methods: {
    setValidatorLocalize(locale) {
      localize(locale)
    },

    changeLang(localeObj) {
      this.$i18n.locale = localeObj.locale
      this.setValidatorLocalize(localeObj.locale)
      localStorage.localeObj = this.$i18n.locale
      EventBus.$emit("localeObj", this.$i18n.locale);
    },
  },

  mounted() {
    if (localStorage.localeObj != null) this.$i18n.locale = localStorage.localeObj
  },

  watch: {
    '$store.state.appConfig.theme': function () {
      if (this.$store.state.appConfig.layout.skin == 'dark') {
        this.theme.topbar.color = ''
      } else {
        this.theme.topbar.color = this.$store.state.appConfig.theme.topbar.textcolor + '!important'

      }
    },
  },

  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'English',
      },
      {
        locale: 'de',
        img: require('@/assets/images/flags/de.png'),
        name: 'Deutsch',
      },
    ]

    return {
      locales,
    }
  },
}
</script>

<style>
@media screen and (max-width: 560px){
  .lang-name{
    display: none;
  }
}

</style>
